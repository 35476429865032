<template>
  <li class="breadcrumbs__item" :class="{breadcrumbs__one_on_mobile: idx === length - 1}">
    <nuxt-link
      v-if="!isLastElem"
      :to="removeLastSlashFromLink(item.url)"
      class="breadcrumbs__text breadcrumbs__text_underline"
      :class="{disabled: !item.url}"
    >
      {{ item.name }}
    </nuxt-link>
    <span v-else class="breadcrumbs__text">
      {{ item.name }}
    </span>
    <span v-if="line" class="breadcrumbs__line">—</span>
  </li>
</template>

<script setup lang="ts">
import 'sass/breadcrumbs.scss';

import {type PropType, computed} from '#imports';
import type {IBreadcrumb} from '@/types/pages/main';
import {removeLastSlashFromLink} from '@/utils';

const props = defineProps({
  item: {type: Object as PropType<IBreadcrumb>, required: true},
  length: {type: Number, required: true},
  idx: {type: Number, required: true},
});

const line = computed(() => props.idx !== props.length);

const isLastElem = computed(() => props.length === props.idx);
</script>
